<template>
  <div>
    <!--Form to add VPS-->
    <div class="row">
      <div class="col-md-10">

        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{ $t('newbanner') }}</h4>
          </div>
          <div class="card-body">

            <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;" v-if="alert_me">
              {{ alert_msg }}
            </div>

            <div class="form-group">
              <label class="col-sm-12 control-label">{{ $t('requestbannerinstruct') }}</label>
              <div class="col-sm-12">
                <vue-dropzone id="bannerupload" :options="dropOptions"
                  ref="dropzone" @vdropzone-file-added="bannerAdded"
                  @vdropzone-complete="bannerUploaded" @vdropzone-sending="injectLink"></vue-dropzone>
              </div>
            </div>

            <div class="form-group" v-if="link_ad">
              <label class="col-sm-12 control-label">{{ $t('linkbannerinstruct') }}</label>
              <div class="col-sm-12">
                <fg-input
                  v-model="adurl" addon-left-icon="fa fa-link"
                  placeholder="https://www.example.com" type="text"></fg-input>
              </div>
            </div>

            <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;" v-if="inprogress"></i>
            <p-button slot="footer" type="info" round v-if="link_ad" v-on:click="finishAd">
              {{ $t('submit') }}
            </p-button>

          </div>
        </div>


      </div>
    </div>

  </div>
</template>
<script>
  import { Modal } from 'src/components/UIComponents'
  import {Tooltip} from 'element-ui'
  import vueDropzone from "vue2-dropzone";
  import VueCookies from 'vue-cookies'
  import CONSTANTS from "../../../../../assets/constants";

  const adwidth = 728;
  const adheight = 90;
  const user_data = JSON.parse(localStorage.getItem('user-info'));
  const upload_url = CONSTANTS.methods.mainapiurl() + "/banner";
  const auth_headers = {
    'Authorization': 'Bearer '+ VueCookies.get('dash-at'),
    'Id_token': VueCookies.get('dash-it')
  };

  export default {
    components: {
      Modal, [Tooltip.name]: Tooltip,
      vueDropzone
    },
    data () {
      return {
        dropOptions: {
          url: upload_url,
          maxFilesize: 1,
          maxFiles: 1,
          thumbnailHeight: 90,
          thumbnailMethod: 'contain',
          resizeHeight: 90,
          acceptedFiles: "image/png,image/jpg,image/jpeg,image/gif",
          autoProcessQueue: false,
          headers: auth_headers,
          dictDefaultMessage: "<i class='nc-icon nc-cloud-upload-94' style='font-size:35px;'></i> "+this.$t('uploadtxt')
        },
        alert_me: false,
        alert_msg: '',
        alert_class: 'text-danger',
        inprogress: false,
        modalvpswarn: false,
        link_ad: false,
        adurl: '',
        requestVPSTxt: this.$t('requestvpsinstructarea')
      }
    },
    methods: {
      bannerAdded(file) {
        this.alert_me = false;
        if (file.status == "error") {
          this.alert_me = true;
          this.alert_msg = this.$t('aduploaderror');
        }
        else {
          //console.log(file);
          this.link_ad = true;
        }
      },
      bannerUploaded(file) {
        this.alert_me = true;
        this.alert_class = "text-success";
        this.alert_msg = this.$t('banner') +": "+ file.name +' '+ this.$t('aduploadsuccess');
        setTimeout(this.cleanUp, 1500);
      },
      cleanUp() {
        this.link_ad = false;
        this.$refs.dropzone.removeAllFiles();
        setTimeout(this.cleanUpMore, 8000);
      },
      cleanUpMore() {
        this.alert_me = false;
      },
      finishAd() {
        this.$refs.dropzone.processQueue();
      },
      injectLink(file, xhr, formData) {
        if (this.adurl === undefined || this.adurl === '') {
          this.adurl = null;
        }
        formData.append('banner_url', this.adurl);
      },
      getError (fieldName) {
        fieldName = this.$t(fieldName);
        return this.errors.first(fieldName);
      },
      validate () {

        this.$validator.validateAll().then(isValid => {
          //console.log(isValid);
          if (isValid) {
            this.inprogress = true;
            // Proceed to creating user and register
            if (this.form.whyvps) {
              this.whyvpsempty = false;
              // Log User In
              //this.$register(this.form).then(user_registered, user_fail);
            }
            else {
              this.inprogress = false;
              this.whyvpsempty = true;
            }
          }
          else {
            this.whyvpsempty = true;
          }

        })

      }
    },
    mounted() {

    }
  }

</script>
<style scoped>
  .card label {
    font-size: 1em;
  }

</style>
